/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from "react"
import { LocaleProvider } from "@context/LocaleContext"

import "./src/styles/global.scss"

export const wrapRootElement = ({ element }) => (
  <LocaleProvider>{element}</LocaleProvider>
)