import React from 'react';

const defaultState = {
  code: 'jp', 
  updateLocale: () => {},
}

const LocaleContext = React.createContext(defaultState);

class LocaleProvider extends React.Component {
  state = {
    code: 'jp',
  }

  updateLocale = (e) => {
    let locale = e.target.innerHTML.toLowerCase();

    localStorage.setItem('yb-locale', locale);
    this.setState({ code: locale });
  }

  componentDidMount() {
    const lsLocale = localStorage.getItem('yb-locale');

    if (lsLocale) {
      this.setState({ code: lsLocale });
    }
  }

  render() {
    const { children } = this.props;
    const { code } = this.state;

    return (
      <LocaleContext.Provider value={{
        code,
        updateLocale: this.updateLocale,
      }}
      >
        {children}
      </LocaleContext.Provider>
    )
  }
}

export default LocaleContext

export { LocaleProvider }