// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-styleguide-js": () => import("./../../../src/pages/styleguide.js" /* webpackChunkName: "component---src-pages-styleguide-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-art-js": () => import("./../../../src/templates/art.js" /* webpackChunkName: "component---src-templates-art-js" */),
  "component---src-templates-bio-js": () => import("./../../../src/templates/bio.js" /* webpackChunkName: "component---src-templates-bio-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-conventions-js": () => import("./../../../src/templates/conventions.js" /* webpackChunkName: "component---src-templates-conventions-js" */),
  "component---src-templates-home-page-js": () => import("./../../../src/templates/home-page.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-tattoo-category-js": () => import("./../../../src/templates/tattoo-category.js" /* webpackChunkName: "component---src-templates-tattoo-category-js" */),
  "component---src-templates-tattoos-js": () => import("./../../../src/templates/tattoos.js" /* webpackChunkName: "component---src-templates-tattoos-js" */)
}

